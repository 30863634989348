import * as React from 'react';

import Toolbar from '@mui/material/Toolbar';

import Typography from '@mui/material/Typography';
import {Link} from 'react-router-dom';
import {Button} from 'antd';
import {Grid} from '@mui/material';
import {useSelector} from 'react-redux';
import {Enrollment} from '../../lib';

function FooterBar() {
  const selectedEnrollment: Enrollment | null = useSelector(
    (state: any) => state.enrollment.selectedEnrollment,
  );
  console.log(
    'selectedEnrollment?.curriculum?.name',
    selectedEnrollment?.curriculum?.name,
  );
  return (
    <Grid item xs={12} style={{textAlign: 'center'}}>
      <Typography
        color="textSecondary"
        variant="subtitle1"
        style={{
          padding: 30,
          fontFamily: 'Tajawal',
          fontWeight: 'bold',
          fontSize: 18,
          display: 'flex',
          justifyContent: 'center',
        }}>
        <Button
          type="primary"
          block
          onClick={() => {
            window.open(
              'https://pikado.io/Peer-to-Peer-Live-Sessions',
              '_blank',
            );
          }}
          style={{
            width: 'max-content',
            marginInline: 10,
            backgroundColor: '#FFD252',
            fontFamily: 'Tajawal',
            fontWeight: 'bold',
            fontSize: 16,
          }}>
          إعرف أكثر
        </Button>
        جرب حصص بيكادو المباشرة للغات مع أولاد وبنات بعمر ١٣ ل ١٧
      </Typography>
      <Typography
        color="textSecondary"
        variant="subtitle1"
        style={{
          fontFamily: 'Tajawal',
          fontWeight: 'bold',
          fontSize: 13,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          alignContent: 'center',
        }}>
        PikaDo LLC، Suite 302, 4001 Kennett Pike, County of New Castle,
        Wilmington, Delaware 19807, USA
      </Typography>
      <div
        style={{
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingBottom: 30,
        }}>
        <Link
          style={{
            fontFamily: 'Tajawal',
            fontWeight: 'bold',
            fontSize: 12,
          }}
          to={{
            pathname: 'https://pikado.io/',
          }}
          target="_blank">
          www.PikaDo.io
        </Link>
        &nbsp; &nbsp;
        <a
          style={{
            fontFamily: 'Tajawal',
            fontWeight: 'bold',
            fontSize: 12,
            color: '#0D6EFD',
            textDecoration: 'underline',
          }}
          onClick={() => {
            window.open(`mailto:info@PikaDo.io?subject=QA`);
          }}
          target="_blank">
          info@PikaDo.io
        </a>
      </div>
      <Typography
        color="textSecondary"
        variant="subtitle1"
        style={{
          fontFamily: 'Tajawal',
          fontWeight: 'bold',
          fontSize: 13,
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          alignContent: 'flex-end',
          marginInline: 20,
        }}>
        curriculum: {selectedEnrollment?.curriculum?.name ?? 'pikado'}
      </Typography>
    </Grid>
  );
}
export default FooterBar;
