import {Typography,Image,  Progress, Divider, List, Modal, Tabs, Button} from 'antd';
import {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import startbutton from '../../assets/cup.png';
import playButton from '../../assets/feedback-teacher.png';
import teacherPlaceholder from '../../assets/profile-icon.jpg';
import checkMark from '../../assets/check-mark-icon.png';
import {
  Loader,
  ProgressModal,
  QuestionCard,
  TestCard,
  TestModal,
} from '../../components';

import './HomePage.scss';

import {ExerciseModal} from '../../components/ExerciseModal';
import {
  AssetType,
  ProgramType,
  QUESTION_TYPE,
  Question,
  Status,
  Test,
} from '../../lib';
import {
  doClearTest,
  doSelectQuestion,
  doSelectTest,
  generateRandomExBasedOnWeakSyllables,
  setCurrentTest,
} from '../../redux/actions';

import Title from 'antd/es/typography/Title';
import moment from 'moment';
import ResponsiveAppBar from '../../components/Appbar';
import FooterBar from '../../components/Footerbar';
import {getDaysDiff} from '../../utils/Dates';
import ReportsHeader from '../../components/ReportsHeader';

const openAIQuestionAsset: Question = {
  id: '-OPEN-AI',
  createdAt: 'Node.generateCreatedAt()',
  updatedAt: 'Node.generateUpdatedAt()',
  name: 'Daily Reading Paragraph',
  name_i18n: '',
  programType: ProgramType.LANGUAGE,
  summary:
    'Press the below button to generate a reading paragraph depends on your repeated syllables mistakes.',
  summary_i18n: '',
  description:
    'Please press the recording button and read the below sentence in a clear voice.',
  description_i18n: '',
  text: 'selectedSentence',
  asset: {
    __typename: 'Asset',
    id: 'adsss',
    createdAt: '',
    updatedAt: '',
    type: AssetType.PHOTO,
    thumbnailUri:
      'https://s3.eu-west-1.amazonaws.com/assets.pikado.io/photo/337778904_1074114464030879_8325695208240193271_n.jpg',
    fileUri:
      'https://s3.eu-west-1.amazonaws.com/assets.pikado.io/photo/337778904_1074114464030879_8325695208240193271_n.jpg',
    title_i18n: '',
    subTitle_i18n: '',
    // position?: number ,
  },
  choices: [],
  keywords: [],
  textAI: 'selectedSentence?.toUpperCase()',
  type: QUESTION_TYPE.READ_SENTENCE,
  __typename: 'Question',
};

export const HomePage = () => {
  const dispatch = useDispatch();
  const teacherPhotoRef = useRef<HTMLDivElement>(null);

  const isLoading = useSelector((state: any) => state.appState.isLoading);
  const todayRandomExercise: Question | null = useSelector(
    (state: any) => state.enrollment.todayRandomExercise,
  );
  const selectedStudent = useSelector(
    (state: any) => state.student.selectedStudent,
  );

  const studentsDetails = useSelector(
    (state: any) => state.student.studentsDetails,
  );

  const [isError, setIsError] = useState<boolean>(false);

  const [isProgressModalVisible, setIsProgressModalVisible] =
    useState<boolean>(false);

  const studentsPlanMap = useSelector(
    (state: any) => state.enrollment.studentPlan,
  );
  const [openExerciseModal, setOpenExerciseModal] = useState<boolean>(false);
  const [openTestModal, setOpenTestModal] = useState<boolean>(false);
  const [planDay, setPlanDay] = useState<string>();

  const selectedEnrollment = useSelector(
    (state: any) => state.enrollment.selectedEnrollment,
  );

  useEffect(() => {
    isEnrollmentReady()
    if (selectedEnrollment?.startDate) {
      let day = getDaysDiff(selectedEnrollment?.startDate ?? '');

      if (day === '0') {
        day = '1';
      }

      setPlanDay(day);
    }
    // console.log("selectedStudent?.school?.classesschool?.[0]?.teacher?.profilePicture?.fileUri",selectedStudent?.school?.classesschool?.[0]?.teacher?.profilePicture?.fileUri,
    // "\n selectedStudent?.school != null",selectedStudent?.school != null, "\n selectedStudent?.school?.classesschool?.[0]?.teacher?.profilePicture?.fileUri && selectedStudent?.school != null",selectedStudent?.school?.classesschool?.[0]?.teacher?.profilePicture?.fileUri || selectedStudent?.school != null
    // )
    if( teacherPhotoRef?.current){
      let photo = selectedStudent?.school != null && selectedStudent.school?.classesschool?.[0]?.teacher?.profilePicture?.fileUri != undefined
      ? selectedStudent.school.classesschool[0].teacher.profilePicture.fileUri
      : require('../../assets/profile-icon.jpg');
      teacherPhotoRef.current.style.backgroundImage  = `url(${photo})` ;
      teacherPhotoRef.current.style.backgroundSize = "cover";
      teacherPhotoRef.current.style.backgroundPosition = "center";
    }
  }, [selectedEnrollment]);

  const isEnrollmentReady = () => {
    if (Object.keys(studentsPlanMap)?.length !== 0 && selectedStudent) {
      return true;
    } else if (
      Object?.keys(studentsPlanMap)?.length !== 0 &&
      selectedStudent &&
      selectedEnrollment?.package.isFree
    ) {
      // console.log("selectedEnrollment",selectedEnrollment)
      return true;
    }
  };

  const renderCard = (test: Test, index: number) => {
    // console.log(`test ${index}`,test)
    if (test?.questions?.length! > 1 && test?.type !== 'QUIZ') {
      return (
        <div
          style={{display: 'contents'}}
          className="col-xs-12 col-sm-6 col-md-6 col-lg-4 rounded-0 me-1"
          key={`selectedMapId_${index}`}>
          <TestCard
            //@ts-ignore
            test={test}
            index={index}
            //@ts-ignore
            isBlur={
              (selectedEnrollment?.package?.isFree ||
                selectedEnrollment.paymentStatus !== Status.DONE) &&
              index > 1
                ? true
                : false
            }
            onClick={() => {
              //@ts-ignore
              dispatch(doSelectTest(test));
              setOpenTestModal(true);
            }}
          />
          {/*@ts-ignore*/}
          {/* {test?.questions?.map((q: Question, questionIndex: number) => {
            return (
              <QuestionCard
                key={q.id}
                //@ts-ignore
                question={q}
                //@ts-ignore
                isBlur={
                  selectedEnrollment.package.isFree && questionIndex > 0
                    ? true
                    : false
                }
                index={index}
                onClick={() => {
                  //@ts-ignore
                  if (test.questions?.length === 1) {
                    //TODO: Fix questions and tests
                    //@ts-ignore
                    dispatch(doSelectQuestion(test.questions[0]));
                  } else {
                    //@ts-ignore
                    dispatch(doSelectQuestion(q));
                  }
                  setOpenExerciseModal(true);
                }}
              />
            );
          })} */}
        </div>
      );
    } else {
      return (
        <div
          className="col-xs-12 col-sm-6 col-md-6 col-lg-4 me-1"
          style={{display: 'contents'}}
          key={`selectedMapId_${index}`}>
          <QuestionCard
            //@ts-ignore
            isBlur={
              (selectedEnrollment?.package?.isFree ||
                selectedEnrollment.paymentStatus !== Status.DONE) &&
              index > 0
                ? true
                : false
            }
            //@ts-ignore
            question={test}
            index={index}
            onClick={() => {
              //@ts-ignore
              if (test?.questions?.length === 1) {
                //TODO: Fix questions and tests
                //@ts-ignore
                dispatch(doSelectQuestion(test.questions[0]));
              } else {
                //@ts-ignore
                dispatch(doSelectQuestion(test));
              }
              setOpenExerciseModal(true);
            }}
          />
        </div>
      );
    }
  };

  const renderTodayPlan = () => {
    // console.log('studentsPlanMap[selectedStudent?.id]',studentsPlanMap[selectedStudent?.id])
    const items =studentsPlanMap[selectedStudent?.id];
    if (!items) return null;
    const videoItems = items?.filter((item: Test) => item.asset.type === "VIDEO").sort((a: Test, b: Test) => (a?.name ?? '').localeCompare(b?.name ?? ''));
    const otherItems = items?.filter((item: Test) => item.asset.type !== "VIDEO").sort((a: Test, b: Test) => (a?.name ?? '').localeCompare(b?.name ?? ''));
    return [...videoItems, ...otherItems].map(
      (test: Test, index: number) => {
        return renderCard(test, index);
      },
    );
  };


  return (
    <div>
      {!openExerciseModal && !openTestModal && <ResponsiveAppBar />}
      {/* <div className            = "reportPageContainer"> */}
        <>
      <ReportsHeader selectedStudent={selectedStudent} />

      <div id="cards_landscape_wrap-2">
        <div className="container">
          <div className="row  gx-6 justify-content-center justify-content-md-between align-items-center">
            <Typography
              color="textSecondary"
              // variant="subtitle1"
              style={{
                fontFamily: 'Tajawal',
                fontWeight: 'bold',
                fontSize: 13,
                // marginInline: 20,
              }}
              className='row p-2 flex-shrink-1 justify-content-start align-items-start align-content-start'
              >
              Day: {planDay}
            </Typography>
            {/* <div className="row w-100 ps-6">
<div className="progress px-0 rounded-0 position-relative">
  <div className="progress-bar w-25 rounded-0" role="progressbar" aria-label="Basic example" aria-valuenow={25} aria-valuemin={1} aria-valuemax={100} />
</div>
  <div className="w-100">

  <p className="progress-value fs-4 w-25 text-end">25%</p>
  </div>
  </div> */}
            {/* <div className="row w-100 mb-3">
  <Typography
              color="textSecondary"
              // variant="subtitle1"
              style={{
                // fontFamily: 'Tajawal',
                fontWeight: 'bold',
                fontSize: 18,
                // marginInline: 20,
                marginBottom: 20
              }}
              className='row text-custom-grey flex-shrink-1 justify-content-start align-items-start align-content-start'
              >
             TEACHER FEEDBACK
            </Typography>
            <div className="col-11 feedback-background ">
              <div className="row align-items-center p-4">
              <div className="col-1  d-flex align-items-center justify-content-center">
              <img src={playButton} className="img-fluid  mx-auto" />
              </div>
              <div className="col-11 d-flex flex-column align-items-start justify-content-center">
              <p className='mb-0 text-custom-grey'>Last feedback: <span className="progress-value"> 26 Sep. 2024</span></p>
              <p className='mb-0'>Press to go to your report and listen to your teacher’s feedback.</p>
              </div>
              </div>
            </div>
            <div className="col-1 px-0   d-flex align-items-center justify-content-center" ref={teacherPhotoRef}>
            <img
            // src={teacherPlaceholder}
            className="img-fluid" />
            </div>
            </div>
            <div className="row w-100 ">
  <Typography
              color="textSecondary"
              // variant="subtitle1"
              style={{
                // fontFamily: 'Tajawal',
                fontWeight: 'bold',
                fontSize: 18,
                // marginInline: 20,
                marginBottom: 20
              }}
              className='row text-custom-grey  flex-shrink-1 justify-content-start align-items-start align-content-start'
              >
             TEACHER HOMEWORK
            </Typography>
            <div className="col-8  homework-background py-2">
              <div className="row align-items-center p-4">

              <div className="col-11 d-flex flex-column align-items-start justify-content-center">
              <p className='mb-0 text-custom-grey'>Homework date: <span className="progress-value"> 26 Sep. 2024</span></p>
              <p className='mb-0'>Press the button and answer the teacher’s homework.</p>
              </div>
              <div className="col-1  d-flex align-items-center justify-content-center">
              <img src={checkMark} className="img-fluid  mx-auto" />
              </div>
              </div>
            </div>
            <div className="col-4 px-0 pe-3  d-flex align-items-center justify-content-center homework-background">
            <Button
              // type="button"
              className="btn-record rounded-0"
              data-toggle="modal"
              data-target=".bd-example-modal-lg"
              // style={{marginTop: 15}}
            >

              <span> Answer question</span>
            </Button>
            </div>
            </div> */}

            {isEnrollmentReady() && !isLoading && (
              <QuestionCard
                //@ts-ignore
                isBlur={
                  selectedEnrollment?.package?.isFree ||
                  selectedEnrollment.paymentStatus !== Status.DONE
                    ? true
                    : false
                }
                //@ts-ignore
                question={openAIQuestionAsset}
                index={10}
                onClick={async () => {
                  const createdAt = moment(todayRandomExercise?.createdAt);
                  const today = moment();
                  // today.add(1, 'days');
                  if (
                    !todayRandomExercise ||
                    !today?.isSame(createdAt, 'days')
                  ) {
                    //@ts-ignore
                    await dispatch(generateRandomExBasedOnWeakSyllables());
                    // if (todayRandomExercise) {
                    setOpenExerciseModal(true);
                    //}
                  } else {
                    //@ts-ignore
                    dispatch(doSelectQuestion(todayRandomExercise));
                    setOpenExerciseModal(true);
                  }
                }}
              />
            )}
            {isEnrollmentReady() && renderTodayPlan()}
          </div>

        </div>
        {openExerciseModal && (
          <ExerciseModal
            closeModal={() => {
              //@ts-ignore
              dispatch(doClearTest());

              setOpenExerciseModal(false);
            }}
          />
        )}

        {openTestModal && (
          <TestModal
            onQuestionsClick={() => {
              setOpenExerciseModal(true);
              setOpenTestModal(false);
            }}
            closeModal={() => {
              //@ts-ignore
              dispatch(doClearTest());
              setOpenTestModal(false);
            }}
          />
        )}
      </div>
      </>
      {isProgressModalVisible && (
        <ProgressModal
          onCloseProgressModal={() => setIsProgressModalVisible(false)}
        />
      )}

      {isLoading ? <Loader title={'Loading...'} /> : null}

      {isError && <Title>Exercises fetching error, try again ⚠️</Title>}

      {isEnrollmentReady() && !isError && <FooterBar />}
    </div>
  );
};
