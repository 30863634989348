import React from 'react';
import Button from 'react-bootstrap/Button';
import {connect} from 'react-redux';

import './index.css';
import '../../public.scss';

import {AssetType} from '../../lib';
import {Modal, Typography} from 'antd';
import {TestState} from '../../redux/reducers/test';
import {AudioImage} from '../AudioImage';
import {RenderVideo} from './RenderVideo';

const {Text} = Typography;

type TestModalState = {
  isPaused: boolean;
};

type TestModalProps = {
  closeModal: () => void;
  onQuestionsClick: () => void;
  test?: TestState;
  dispatch?: any;
};

export class _TestModal extends React.Component<
  TestModalProps,
  TestModalState
> {
  videoPlayerRef: any;
  constructor(props: TestModalProps) {
    super(props);
    this.state = {
      isPaused: true,
    };
    this.videoPlayerRef = React.createRef();
  }

  componentDidMount() {
    this.videoPlayerRef?.current?.play();
  }

  renderModalFooter = () => {
    return (
      <div className="flex-direction-row">
        <Text>{this.props.test?.currentTest?.questions?.length} Questions</Text>

        <div className="Buttons_Recording">
          <Button
            onClick={() => {
              this.props.onQuestionsClick();
            }}
            variant=""
            className="btn-record ">
            <span>Go to Questions</span>
          </Button>
        </div>
      </div>
    );
  };

  renderVideo = () => {
    return (
      <div style={{display: 'inline-block'}}>
        <video
          controlsList="nodownload"
          controls={true}
          onClick={() => {
            this.videoPlayerRef.current.paused
              ? this.videoPlayerRef.current.pause()
              : this.videoPlayerRef.current.play();
          }}
          ref={this.videoPlayerRef}
          poster={this.props.test?.currentTest?.asset?.thumbnailUri!}
          src={this.props.test?.currentTest?.asset?.fileUri!}
          style={{width: '100%', resize: 'vertical', display: 'flex'}}
          height={'60%'}
        />
      </div>
    );
  };

  renderPhoto = () => {
    console.log(this.props);
    console.log(this.props.test?.currentTest);
    return (
      <AudioImage
        audioSrc={this.props.test?.currentTest?.asset?.fileUri!}
        imageSrc={this.props.test?.currentTest?.asset?.thumbnailUri!}
        isPaused={this.state.isPaused}
        onImageClick={() => {
          this.setState({isPaused: !this.state.isPaused});
        }}
      />
    );
  };

  renderQuestion = () => {
    switch (this.props.test?.currentTest?.asset?.type) {
      case AssetType.PHOTO:
        return this.renderPhoto();
      case AssetType.VIDEO:
        return (
          <RenderVideo
            poster={this.props.test?.currentTest?.asset?.thumbnailUri!}
            videoUrl={this.props.test?.currentTest?.asset?.fileUri!}
          />
        );
    }
  };
  render() {
    console.log('this.props.question->', this.props);
    return (
      <Modal
        closable={false}
        className={'exercise-modal'}
        bodyStyle={{textAlign: 'left'}}
        style={{
          maxHeight: '100%',
          textAlign: 'left',
        }}
        width={'50%'}
        centered={true}
        title={this.props.test?.currentTest?.description}
        open={true}
        onOk={() => {}}
        footer={this.renderModalFooter()}
        onCancel={() => {
          this.props.closeModal();
        }}>
        <div
          className="Modal_Res_Test"
          style={{marginTop: '20px', position: 'relative'}}>
          {this.renderQuestion()}
          {/* <Text
            className={'test-or-question-text'}
            style={{
              position: 'absolute',
              top: 'auto',
              display: 'inline-block',
              alignSelf: 'center',
              marginLeft: '20px',
              textAlign: 'left',
              fontSize: 20,
            }}>
            {this.props.test?.currentTest?.name}
          </Text> */}
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    test: state.test,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    dispatch: dispatch,
  };
};

export const TestModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(_TestModal);
